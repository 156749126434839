import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Container from './Container';
import HeaderSpacer from './HeaderSpacer';
import HeroBackground from './HeroBackground';
import SectionTitle from './SectionTitle';

type Props = {
  title: string;
  text?: string;
};

const HeroInnerPage = ({ title, text }: Props) => {
  return (
    <>
      <div className="relative bg-blue text-blue">
        <HeaderSpacer />
        <HeroBackground />
        <div className="relative z-10">
          <Container>
            <div className="prose prose-invert order-2 mx-auto max-w-4xl py-8 prose-h1:mb-8 prose-h1:text-center prose-h1:text-2xl prose-h1:after:mx-auto prose-h1:after:mt-[0.25em] prose-h1:after:block prose-h1:after:h-[2px] prose-h1:after:w-[5rem] prose-h1:after:bg-emerald-500 prose-p:m-0 sm:pb-[10vh] sm:pt-[6vh] prose-p:sm:text-center md:order-1 prose-h1:md:text-4xl prose-p:md:text-lg prose-h1:xl:text-5xl">
              <h1 data-sal="slide-up" style={{ '--sal-duration': '.5s' } as React.CSSProperties}>
                {title}
              </h1>
              <p dangerouslySetInnerHTML={{ __html: text || '' }} />
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default HeroInnerPage;
