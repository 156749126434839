import * as React from 'react';
import type { HeadFC } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';
import Seo from '../components/Seo';
import Layout from '../components/Layout';
import Container from '../components/Container';
import HeroInnerPage from '../components/HeroInnerPage';
import Section from '../components/Section';
import Button from '../components/Button';
import { PageContext } from 'gatsby-plugin-react-i18next/dist/types';
import SectionTitle from '../components/SectionTitle';
import { ArrowRightCircleIcon } from '@heroicons/react/24/outline';
import CarriersForm from '../components/CarriersForm';
import { StaticImage } from 'gatsby-plugin-image';
const CarriersPage = ({ data }: any) => {
  const { t } = useTranslation();

  const { allMarkdownRemark } = data;
  const { nodes } = allMarkdownRemark;

  return (
    <Layout>
      <HeroInnerPage title={t('page.carriers.title')} text={t('page.carriers.text')} />
      <Section>
        <Container>
          <div className="prose prose-h1:mt-0 prose-h2:mt-0 prose-h3:mt-0 prose-h4:mb-8">
            <SectionTitle center>{t('page.carriers.subtitle')}</SectionTitle>
            <div className="grid grid-cols-2 items-start gap-32">
              <div>
                <div data-sal="slide-up" style={{ '--sal-duration': '.5s' } as React.CSSProperties}>
                  <StaticImage
                    src="../images/carriers.png"
                    alt=" "
                    placeholder="none"
                    layout="constrained"
                    formats={['auto', 'webp', 'avif']}
                  />
                </div>
                <div dangerouslySetInnerHTML={{ __html: t('page.carriers.text_2') }} />
              </div>
              <div>
                <p>
                  <strong>{t('page.carriers.positions_title')}</strong>
                </p>
                <ul>
                  {nodes.map((node: any, i: any) => {
                    return (
                      <li key={i}>
                        <span>{node.frontmatter.title}</span>
                      </li>
                    );
                  })}
                </ul>
                <p>
                  <strong>{t('page.carriers.locations_title')}</strong>
                </p>
                <div className="not-prose border-l pl-4">
                  <div dangerouslySetInnerHTML={{ __html: t('page.carriers.locations_text') }} />
                </div>
                <p>
                  <strong>{t('page.carriers.salary_title')}</strong>
                </p>
                <div className="not-prose border-l pl-4">
                  <div dangerouslySetInnerHTML={{ __html: t('page.carriers.salary_text') }} />
                </div>
              </div>
            </div>
            <hr />
            <div className="mx-auto max-w-screen-md">
              <div className="flex flex-col gap-8 md:flex-row md:gap-16">
                <div
                  className="flex-shrink-0"
                  data-sal="slide-right"
                  style={{ '--sal-duration': '.5s' } as React.CSSProperties}
                >
                  <StaticImage
                    src="../images/contact.png"
                    alt=" "
                    placeholder="none"
                    layout="fixed"
                    width={196}
                    formats={['auto', 'webp', 'avif']}
                  />
                </div>
                <div className="grow">
                  <CarriersForm />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </Layout>
  );
};

export default CarriersPage;

export const Head: HeadFC = ({ location, pageContext }) => {
  const { language } = pageContext as PageContext;

  type Title = {
    [key: string]: string;
  };

  const title = {
    fr: 'Carrières',
    en: 'Careers',
  } as Title;

  return <Seo location={location} title={`${title[language]} | Austra`} />;
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: { locale: { eq: $language } }
        fileAbsolutePath: { regex: "/carriers/i" }
      }
    ) {
      nodes {
        frontmatter {
          title
        }
        id
      }
    }
  }
`;
